

let host = document.location.host;
let apiUrl = 'http://66.179.251.9:6040/'

const environment = {
//  api: 'https://virtualpalapi.jcsoftwaresolution.in/',
api:'https://stageapi.myvirtualpal.com/',
  tinyKey: 'zua062bxyqw46jy8bhcu8tz9aw6q37sb1pln5kwrnhnr319g',
  map_api_key: 'AIzaSyCbRhC6h9Pp43-5t_Knyrd_ewAdLMIJtCg',
  planTypeId: '64ad2ebce5aa778d26a54f02',
  EmployerId: "64e5cdfc5bb9a041a39046d5",
  VirtualId: "64e83a928599356bddc2fa00",
  RecruiterId: "652393ee64451a10065b76eb",
  userRoleId: '64b15102b14de6c28838f7d2',
  adminRoleId: '64b6af9ed91c0152642aa581',
  resellerTypeId: '64b23b7d690d1d5f7ae76102',
  googleMapAPiKey: "AIzaSyDqvi9Hk4fHNqCoHlSVp8r7-J6Wk7IhMOw",
  productTypeId: '64a7d198fa039f179c0320ca',
  stripe_publish_key: "pk_test_51KyZElEGnmCJcey2kk3glVh6N3xDcL9EGmxOOX6POD0xsaLNUJIBVZcNM4Kvg2Pe5Gyln1bn7Ge0WkdkbyQCRb1J00stuxPQRm",
SocketURL:'https://chat.myvirtualpal.com/',
};
export default environment;
