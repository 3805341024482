import { useEffect, useState } from "react";
import loader from "../../../methods/loader";
import ApiClient from "../../../methods/api/apiClient";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { toast } from "react-toastify";
import Layout from "../../../components/global/layout";
import { useSelector } from "react-redux";
import environment from "../../../environment";
import Multiselect from "multiselect-react-dropdown";
import { v4 as uuidv4 } from 'uuid';

export default function AddEditAssessments() {
  const [AddQuestion, setAddQuestion] = useState(false);
  const { id } = useParams();
  const user = useSelector((state) => state.user);
  const Navigate = useHistory();
  const [form, setform] = useState({ name: "", passingScrore: 0, weightagePerQuestion: 0, });
  const [submitted, setsubmitted] = useState(false);
  const [NewForm1, setNewForm1] = useState({ assessment: "" })
  const [hardSkills, setHardSkills] = useState([])
  console.log(hardSkills, '==hardSkills');

  const handleSubmit = (e) => {
    e.preventDefault();
    if (SelectedSkills.length == 0) { toast.error("Please Select Skills"); return false }
    loader(true);
    setsubmitted(true);
    let method = "post";
    const payload = { ...form, skills: SelectedSkills, skillIds: SelectedSkills.map((item) => item.id), professionId: SelectedProfessions?.map((item) => item?.id) };
    let url = "assessment";
    if (id) {
      method = "put";
      payload.id = id;
    } else {
      delete payload.id;
    }

    const array = MCQOptions;

    newform.map((item, index) => newform[index].options = MCQOptions[`${index}`]);
    const answerarray = []
    if (newform.map((item) => item.questionType == "MCQ")) {
      Object.keys(array).map((item, index) => {
        answerarray.push(array[item].some((item) => item.isCorrectAnswer == true))
      })
      if (answerarray.some((item) => item == false)) {
        toast.error("Please Select Correct Answer for Question");
        loader(false)
        return false;
      }
    }
    ApiClient.allApi(url, payload, method).then((result) => {
      if (result.success) {
        setNewForm1({ ...NewForm1, assessment: result.data })
        if (AddQuestion) {
          handleQuestionSubmit(e, result.data)
        }
        else {
          toast.success(result.message);
          Navigate.push("/assessment");
        }
      }
      loader(false);
    });
  };

  //  ############ Skills Types########
  const [Assessmentdata, setAssessmentdata] = useState([]);
  const GetSkillsTypes = () => {
    loader(true);
    ApiClient.get(`skill/types?status=active`).then((res) => {
      if (res.success) {
        setAssessmentdata(res.data);
      }
      loader(false);
    });
  };

  ///////end Skills Types ///////////


  useEffect(() => {
    GetSkillsTypes();
    if (id) {
      ApiClient.get(`assessment?id=${id}`).then((result) => {
        if (result.success) {
          const newdata = result.data;
          setSelectedSkills(newdata.skills);
          setSelectedProfessions(newdata?.professionId);
          setform({ name: newdata.name, status: newdata.status, passingScrore: newdata.passingScrore, weightagePerQuestion: newdata.weightagePerQuestion });
        }
      });
    }
  }, []);

  const isAllow = (key = "") => {
    let permissions = user.role?.permissions;
    let value = permissions?.[key];
    if (user.role?._id == environment.adminRoleId) value = true;
    return value;
  };

  // const [SkillsData, setSkillsData] = useState([]);
  const [SelectedSkills, setSelectedSkills] = useState([]);
  // const GetSkills = () => {
  //   loader(true);
  //   ApiClient.get(`skills?status=active`).then(res => {
  //     if (res.success) {
  //       const data = res.data;
  //       const Array = []
  //       data.map((item, index) => {
  //         Array.push({ name: item.name, id: item.id });
  //       })
  //       setSkillsData(Array);
  //     }
  //   })
  // }
  // useEffect(() => { GetSkills() }, [])



  const [AssesmentsData, setAssesmentData] = useState([]);
  const [newform, setnewform] = useState([{ questionType: "", question: "", options: [] }])
  const [MCQOptions, setMCQOptions] = useState({
    "0": [
      { id: "", value: "", isCorrectAnswer: false }, { id: "", value: "", isCorrectAnswer: false }
    ]
  });



  const handleQuestionSubmit = (e, assesment = "") => {
    e.preventDefault();
    const array = MCQOptions;
    if (!id) {
      Object.keys(array).map((item, indexo) => {
        MCQOptions[item].map((item, index) => {
          array[indexo][index]["id"] = uuidv4(item.value);
        })
      })
    }
    newform.map((item, index) => newform[index].options = MCQOptions[`${index}`]);
    const answerarray = []
    if (newform.map((item) => item.questionType == "MCQ")) {
      Object.keys(array).map((item, index) => {
        answerarray.push(array[item].some((item) => item.isCorrectAnswer == true))
      })
      if (answerarray.some((item) => item == false)) {
        toast.error("Please Select Correct Answer for Question");
        return false;
      }
    }
    // loader(true);
    setsubmitted(true);
    let method = "post";

    let url = "assessment/question";
    newform.forEach((item, index) => newform[index]["assessment"] = assesment == "" ? NewForm1.assessment : assesment)
    let payload = { "data": newform }
    if (id) {
      method = "put";
      const newpayload = { ...payload.data[0] };
      newpayload["id"] = id;
      payload = newpayload
    } else {
      // delete payload.id;
    }
    ApiClient.allApi(url, payload, method).then((result) => {
      if (result.success) {
        toast.success("Assessment Added Successfully");
        Navigate.push("/assessment");
      }
      loader(false);
    });
  };

  const [Questiondata, setQuestiondata] = useState([]);
  const GetQuestionType = () => {
    loader(true);
    ApiClient.get(`assessments?status=active`).then((res) => {
      if (res.success) {
        setQuestiondata(res.data);
      }
      loader(false);
    });
  };

  // useEffect(() => {
  //   GetQuestionType();
  //   if (id) {
  //     ApiClient.get(`assessment/question?id=${id}`).then((result) => {
  //       if (result.success) {
  //         const newdata = result.data;
  //         setform({ 
  //           assessment:newdata.assessment&&newdata.assessment?.id,
  //         });
  //         setnewform([{question:newdata.question,questionType:newdata.questionType}]);
  //         setMCQOptions({"0":newdata.options}) 
  //       }
  //     });
  //   }
  // }, []);



  const HandleAddOptions = (e, index) => {
    e.preventDefault();
    const obj = MCQOptions;
    const array = MCQOptions[index];
    array.push({ id: "", value: "", isCorrectAnswer: false })
    obj[index] = array;
    setMCQOptions({ ...obj })
    loader(false)
  }
  const HandleChange = (e, index, Firstindex) => {
    const { value, name } = e.target;
    const obj = MCQOptions;
    const mcq = MCQOptions[Firstindex]
    obj[Firstindex][index][name] = value;
    // setMCQOptions([...MCQOptions[Firstindex]],mcq[index][name]=value)
    setMCQOptions({ ...obj })

  }
  const HandleDelete = (e, index, mainindex) => {
    e.preventDefault()
    const obj = MCQOptions;
    const newarray = MCQOptions[index];
    newarray.splice(mainindex, 1);
    obj[index] = newarray;
    setMCQOptions({ ...obj });
  }

  const HandleCheckboxChange = (e, indexo, mainindex) => {
    const { checked } = e.target;
    const obj = MCQOptions;
    const array = MCQOptions[indexo];
    if (checked) {
      array.map((item, index) => {
        if (index == mainindex) {
          array[index]["isCorrectAnswer"] = true
        }
        else {
          array[index]["isCorrectAnswer"] = false;
        }
      })
    }
    else {
      array.map((item, indexo) => {
        array[indexo]["isCorrectAnswer"] = false;
      })
    }
    obj[indexo] = array
    setMCQOptions({ ...obj });
  }

  const HandleCommonChange = (e, index, type = "") => {
    const { value, name } = e.target;
    const myform = [...newform];
    if (value == "TrueFalse") {
      const array = newform;
      const optio = MCQOptions;
      optio[`${array.length - 1}`] = [{ id: "", value: "True", isCorrectAnswer: false }, { id: "", value: "False", isCorrectAnswer: false }]
      setMCQOptions({ ...optio })
    }
    if (MCQOptions[`${index}`] && value != "TrueFalse" && type == "type") {
      MCQOptions[`${index}`].map((item) => {
        item.value = "";
      })
    }
    setnewform([...myform], myform[index][name] = value)
  }

  const HandleAddMore = (e) => {
    e.preventDefault();
    const array = newform;
    array.push({ question: "", questionType: "", options: [] })
    setnewform([...array])
    const optio = MCQOptions;
    optio[`${array.length - 1}`] = [{ id: "", value: "", isCorrectAnswer: false }, { id: "", value: "", isCorrectAnswer: false }]
  }
  const HandleDeleteMain = (e, index) => {
    e.preventDefault();
    const newar = newform;
    newar.splice(index, 1);
    setnewform([...newar])
  }
  const [SelectedProfessions, setSelectedProfessions] = useState([]);
  const getHardSkills = (profession = []) => {
    ApiClient.get(`profession/list?search=&page=1&count=50&status=active&hardSkillId=&professionId=${profession?.toString()}`).then((res) => {
      if (res.success) {
        let data = []
        res?.data?.map((item) => {
          item?.hardSkillDetails?.map((itm) => {
            data.push({ id: itm?.id || itm?._id, name: itm?.name })
          })
        })
        setHardSkills(data)
      }
    })
  }
  useEffect(() => {
    getHardSkills(SelectedProfessions)
  }, [SelectedProfessions])

  const [AllProfessions, setAllProfessions] = useState([]);
  const GetAllPrfessions = () => {
    loader(true);
    ApiClient.get(`professions?status=active`).then((res) => {
      if (res.success) {
        let data = []
        res?.data?.map((itm) => {
          if (!data.find((item) => item?.name == itm?.name))
            data.push({ id: itm?.id || itm?._id, name: itm?.name })
        })
        setAllProfessions(data)
      }
      loader(false)
    })
  }
  useEffect(() => {
    GetAllPrfessions()
  }, [])

  const professionValueFinder = (values) => {
    let data = [];
    AllProfessions.map((item) => {
      if (values.includes(item?._id)) {
        data.push({ id: item?.id || item?._id, name: item?.name })
      }
    })
    return data
  }

  return (
    <Layout>
      <div className="heightVH">
        <h3 className="hedding d-flex gap-1 align-items-center mb-3">
          <a onClick={(e) => Navigate.goBack()}>
            <i className="fa fa-angle-double-left fontBack" title='Back' aria-hidden="true"></i>
          </a>
          {id ? "Edit" : "Add"} Assessment
        </h3>
        <form onSubmit={(e) => handleSubmit(e)}>
          <div className="pprofile1">

            <div className="form-row">
              <div className="col-md-12 text-right ">
                {/* <i class="fa fa-trash text-danger" aria-hidden="true"></i> */}
              </div>
              <div className="col-md-6 mb-3">
                <label>
                  Name<span className="star">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={form.name}
                  onChange={(e) => setform({ ...form, name: e.target.value })}
                  required
                />
              </div>

              {/* <div className="col-md-6 mb-3">
              <label>
                Totalscore<span className="star">*</span>
              </label>
              <input
                type="number"
                className="form-control"
                required
              />
            </div> */}

              <div className="col-md-6 mb-3">
                <label>
                  Weightage per question<span className="star">*</span>
                </label>
                <input
                  type="number"
                  className="form-control"
                  value={form.weightagePerQuestion}
                  onChange={(e) => setform({ ...form, weightagePerQuestion: e.target.value })}
                  required
                />
              </div>
              <div className="col-md-6 mb-3">
                <label>
                  Minimum passing score<span className="star">*</span>
                </label>
                <input
                  type="number"
                  className="form-control"
                  value={form.passingScrore}
                  onChange={(e) => setform({ ...form, passingScrore: e.target.value })}
                  required
                />
              </div>


              {/* <div className="col-md-6 mb-3">
              <label>
              skill<span className="star">*</span>
              </label>
              <input
                type="text"
                className="form-control"
                value={form.skill}
                onChange={(e) => setform({ ...form, skill: e.target.value })}
                required
              />
            </div> */}

              <div className="col-md-6 mb-3">
                <label>
                  Profession<span className="star">*</span>
                </label>
                <Multiselect
                  displayValue="name"
                  options={AllProfessions}
                  className="text-capitalize"
                  required
                  selectedValues={professionValueFinder(SelectedProfessions)}
                  onSelect={e => setSelectedProfessions(e?.map((item) => item?.id))}
                  onRemove={e => setSelectedProfessions(e?.map((item) => item?.id))}
                />
              </div>
              <div className="col-md-6 mb-3">
                <label>
                  Skills<span className="star">*</span>
                </label>
                <Multiselect
                  displayValue="name"
                  options={hardSkills}
                  className="text-capitalize"
                  required
                  selectedValues={SelectedSkills}
                  onSelect={e => setSelectedSkills(e)}
                  onRemove={e => setSelectedSkills(e)}
                />
                {/* <select
                className="form-control"
                required
                onChange={(e) =>
                  setform({ ...form, skillType: e.target.value })
                }
                value={form.skillType} 
              >
                <option value="">Select Master Skill</option>
                {Assessmentdata.map((item, index) => (
                  <option className="text-capitalize" value={item.id}>
                    {item.name}
                  </option>
                ))}
              </select> */}
              </div>
              {!id && AddQuestion == false ? <button className="btn btn-primary" onClick={e => setAddQuestion(true)}>Add Questions</button> : null}
              {id ? <button className="btn btn-primary" onClick={e => Navigate.push(`/questions/${id}`)}>Edit Questions</button> : null}


              {/* <div className="col-md-6 mb-3">
            <div className="check_cls ml-4">
  <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault"/>
  <label className="form-check-label" for="flexCheckDefault">
    Yes
  </label>
</div>
<div className="check_cls ml-4">
  <input className="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked/>
  <label className="form-check-label" for="flexCheckChecked">
    No
  </label>
  </div>
</div> */}

              {/* <div className="col-md-6 mb-3">
              <label>
                Status<span className="star">*</span>
              </label>
              <SelectDropdown
                id="statusDropdown"
                displayValue="name"
                placeholder="Select Status"
                intialValue={form.status}
                result={(e) => {
                  setform({ ...form, status: e.value });
                }}
                options={statusModel.list}
              />
              {submitted && !form.status ? (
                <div className="text-danger">Status is Required</div>
              ) : (
                <></>
              )}
            </div> */}


            </div>
            {AddQuestion ? <div className=" ">
              {/* <h3 className="ViewUser">{id ? "Edit" : "Add"} Questions</h3> */}
              <div className=" ">
                {newform.map((item, index) => (
                  <div className="row mb-3">
                    <div className="col-md-6">
                      <label>
                        Questions<span className="star">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="question"
                        value={item.question}
                        onChange={(e) => HandleCommonChange(e, index)}
                        required
                      />
                    </div>

                    <div className="col-md-6">
                      <label>
                        Question type<span className="star">*</span>
                      </label>
                      <select
                        className="form-control qus_input"
                        required
                        name="questionType"
                        onChange={(e) => HandleCommonChange(e, index, "type")}
                        value={item.questionType}
                      >
                        <option value="">Select Question Type</option>
                        <option className="text-capitalize" value="MCQ">
                          Multiple Choice Questions
                        </option>
                        {/* <option className="text-capitalize" value="SingleLineAnswer">
                  Single line answers
                </option> */}
                        <option className="text-capitalize" value="TrueFalse">
                          True/False
                        </option>
                      </select>
                      {index == 0 ? null : <i className="fa fa-trash text-danger d-inline overlap_input qus_del" onClick={e => HandleDeleteMain(e, index)}></i>}

                    </div>
                    {item.questionType != ""
                      ? MCQOptions[index].map((itm, indexo) => (
                        <div className="col-md-6">
                          <label className="opecity_0"> </label>
                          <div class="input-group mb-3">
                            <div class="input-group-prepend">
                              <div class="input-group-text radius_radio_input">
                                <input type="checkbox" name="isCorrectAnswer" value={true} onChange={e => HandleCheckboxChange(e, index, indexo)} checked={itm.isCorrectAnswer} /> {indexo == 0 || indexo == 1 ? null : <i className="fa fa-trash text danger" onClick={e => HandleDelete(e, index, indexo)}></i>}
                              </div>
                            </div>
                            <input type="text" onChange={e => HandleChange(e, indexo, index)} disabled={item.questionType != "TrueFalse" ? false : true} name="value" value={itm.value} required class="form-control radus_checkbox" aria-label="Text input with checkbox" />
                          </div>
                          {/* {/ {/ <input value={item.value} /> /} /} */}
                          {/* <input
                      className="m-2"
                      type="text"
                      id="html"
                      name="value"
                      onChange={e=>HandleChange(e,index)} 
                      value={item.value}
                      required
                    /> */}
                        </div>
                      ))
                      : null}
                    {item.questionType == "MCQ" ? <div className="text-right mt-1">
                      <button className="btn btn-primary mt-1 mb-4" onClick={e => HandleAddOptions(e, index)}>Add Option</button></div> : null}
                  </div>))}
                {id ? null : <button className="btn btn-info mt-1 mb-4" onClick={e => HandleAddMore(e)}>Add Question</button>}
                {/* <br/> */}

              </div>


            </div> : null}

            <div className="text-right">
              {/* <button
              type="button"
              className="btn btn-secondary discard mr-2"
              onClick={(e) => Navigate.goBack()}
            >
              Back
            </button> */}
              {!id || (id && isAllow("editSkillType")) ? (
                <button type="submit" className="btn btn-primary">
                  Save
                </button>
              ) : null}
            </div>
          </div>

        </form></div>
    </Layout>
  );
}
